import React from "react";
import { Link } from "react-router-dom";

class Terms extends React.Component {
    render() {
        return (
            <main>
                <script
                    src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
                    crossOrigin="anonymous"
                ></script>

                <nav className="navbar navbar-expand-lg lightblue">
                    <div className="container-fluid p-3 px-4">
                        <Link className="navbar-brand" to="/">
                            <img
                                src="images/Logo.png"
                                alt="PharmaQue Logo"
                                className="logo img-fluid"
                            />
                            <span className="fs-3 fw-bold darkfont">PharmaQue</span>
                        </Link>

                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item p-1 px-3">
                                    <Link className="nav-link fs-4 darkfont" aria-current="page" to="/">Home</Link>
                                </li>
                                <li className="nav-item p-1 px-3">
                                    <Link className="nav-link fs-4 darkfont" to="/About">About</Link>
                                </li>
                                <li className="nav-item p-1 px-3">
                                    <Link className="nav-link fs-4 darkfont" to="/Plans">Plans</Link>
                                </li>
                                <li className="nav-item p-1 px-3">
                                    <Link to="https://www.pharmaque.co.uk/" className="btn btn-primary fs-4 p-2 px-4 bold">Sign In</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                <div className="container-fluid p-4 lightblue">
                    <div className="col-sm-12 px-4 mt-4">
                        <h1 className="h2 fw-bold darkfont center">Official Terms and Conditions - PharmaQue</h1>
                    </div>
                    <div className="col-sm-12 px-4 mt-5 mb-5">
                        <p className="fs-4 darkfont">Please read the following Terms and Conditions related to your use of pharmaque.com</p>
                        <p className="fs-4 darkfont bold mb-5">Effective Date: 1st November, 2024</p>
                        <p className="fs-5 darkfont">Welcome to PharmaQue, operated by StudyQue Ltd. By purchasing or using our services, you agree to the following terms and conditions.</p>

                        <h2 className="h3 fw-bold darkfont">1. Service Overview</h2>
                        <p className="fs-5 darkfont">PharmaQue provides an online question bank for students preparing for the pharmacy pre-registration exam. We offer both free demo access and paid subscriptions. The following options are available:</p>
                        <ul className="fs-5 darkfont">
                            <li>Free access to a demo version.</li>
                            <li>Paid access for a duration of 3 months or 9 months.</li>
                        </ul>

                        <h2 className="h3 fw-bold darkfont">2. Payment and Subscription</h2>
                        <p className="fs-5 darkfont">All purchases are final and non-refundable. Upon purchasing, users will have access to PharmaQue for the selected period (3 months or 9 months).</p>
                        <p className="fs-5 darkfont">We will send a renewal reminder via email 7 days before the subscription expires. Users can choose to renew their subscription, but renewal is not automatic.</p>

                        <h2 className="h3 fw-bold darkfont">3. Usage Restrictions</h2>
                        <p className="fs-5 darkfont">Users are strictly prohibited from downloading, screenshotting, sharing, or reproducing any part of the question bank.</p>
                        <p className="fs-5 darkfont">Any violation of these terms may result in the suspension or termination of access without refund.</p>

                        <h2 className="h3 fw-bold darkfont">4. Data Collection</h2>
                        <p className="fs-5 darkfont">We collect usernames, email addresses, and other personal information necessary to create your account.</p>
                        <p className="fs-5 darkfont">Payment information, including your name, bank details, and billing address, will be collected and processed securely by Stripe Payments.</p>
                        <p className="fs-5 darkfont">All data collected is stored online, and we strive to take appropriate measures to protect user information.</p>

                        <h2 className="h3 fw-bold darkfont">5. Content Ownership</h2>
                        <p className="fs-5 darkfont">All content, including but not limited to the questions, answers, and explanations, is the intellectual property of PharmaQue, a service under StudyQue Ltd.</p>
                        <p className="fs-5 darkfont">Users may not use the content for any commercial purposes or share it with others without express permission.</p>

                        <h2 className="h3 fw-bold darkfont">6. Liability Disclaimer</h2>
                        <ul className="fs-5 darkfont">
                            <li>
                                <strong>Accuracy of Content:</strong> PharmaQue provides practice questions to assist users in exam preparation, but does not guarantee success in any exams. The use of our materials is at the user’s own discretion.
                            </li>
                            <li>
                                <strong>Service Availability:</strong> We strive to ensure the availability of the site but are not responsible for any downtime due to maintenance or unexpected technical issues.
                            </li>
                            <li>
                                <strong>Data Security:</strong> While we take reasonable measures to protect user data, StudyQue Ltd will not be held liable for any breaches beyond our control.
                            </li>
                        </ul>

                        <h2 className="h3 fw-bold darkfont">7. Governing Law</h2>
                        <p className="fs-5 darkfont">These terms are governed by and construed in accordance with the laws of the United Kingdom. Any disputes arising from these terms will be subject to the jurisdiction of UK courts.</p>

                        <h2 className="h3 fw-bold darkfont">8. Updating these terms and conditions</h2>
                        <p className="fs-5 darkfont">We may update these terms and conditions from time to time. If we make changes, we will notify users by updating the date at the top of the terms. It is the user's responsibility to check for updates.</p>

                        <h2 className="h3 fw-bold darkfont">9. Violation of terms and conditions</h2>
                        <p className="fs-5 darkfont">We reserve the right to suspend or terminate any user’s access to the site for violation of these terms without notice or refund.</p>

                        <h2 className="h3 fw-bold darkfont">10. Contacting PharmaQue</h2>
                        <p className="fs-5 darkfont">If you have any questions about these terms and conditions, please contact us at <a href="mailto:pharmaque23@gmail.com" className="darkfont bold removeunderline">pharmaque23@gmail.com</a>.</p>
                    </div>
                </div>

                <div className="container-fluid p-4 darkblue center">
                    <Link to="/Terms" className="removeunderline">
                        <span className="fs-5 whitetext mt-2 mb-1 mx-4 bold inlineblock">Terms and Conditions</span>
                    </Link>
                    <Link to="/Privacy" className="removeunderline">
                        <span className="fs-5 whitetext mt-2 mb-1 mx-4 bold inlineblock">Privacy Policy</span>
                    </Link>
                    <Link to="mailto:pharmaque23@gmail.com" href="mailto:pharmaque23@gmail.com" className="removeunderline">
                        <span className="fs-5 whitetext mt-2 mb-1 mx-4 bold inlineblock">Contact Us</span>
                    </Link>
                    <Link to="/About" className="removeunderline">
                        <span className="fs-5 whitetext mt-2 mb-1 mx-4 bold inlineblock">About Us</span>
                    </Link>
                    <Link to="#" className="removeunderline">
                        <span className="fs-5 whitetext mt-2 mb-1 mx-4 bold inlineblock">Work For Us</span>
                    </Link>

                    <p className="fs-5 mt-3 mb-2 whitetext">Copyright © PharmaQue 2023. All rights reserved.</p>
                </div>
            </main>
        );
    }
}

export default Terms;
