import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';
import About from './pages/About.js';
import Plans from './pages/Plans.js';
import Home from './pages/Home.js';
import Privacy from './pages/Privacy.js'
import Terms from './pages/Terms.js'

function App() {
  return (
<Router>
      <Routes>
        <Route exact path = "/" Component={Home}/>
        <Route path = "/About" Component={About}/>
        <Route path = "/Plans" Component={Plans}/>
        <Route path = "/Privacy" Component={Privacy}/>
        <Route path = "/Terms" Component={Terms}/>
      </Routes>
    </Router>

    
  );
}

export default App;
